<template>
  <div class="regsit">
    <left-img :target="'/regist'"></left-img>
    <el-form label-width="80px" :model="regist_form" :rules="regist_rules" ref="form_ref">
      <div class="regist_header">企业认证</div>
      <el-form-item label="企业名称" prop="enterpriseName">
        <el-input id="bgColor" v-model="regist_form.enterpriseName"></el-input>
      </el-form-item>
      <el-form-item ref="form_ref" label="认证信息" prop="imageUrl" style="margin-top:-30px" class="avatar_uploader">
        <div class="upload_img">
          <!-- 反面 -->
          <el-upload
            class="avatar-uploader position"
            :action="BaseUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :headers="headers"
            :auto-upload="false"
            ref="upload_img"
            :data= "regist_form"
            :limit="1"
          >
            <img v-if="q_image" :src="q_image" class="avatar" />
            <img src="../../public/images/yingyezhizhao.png" v-else class="avatar-uploader-icon" />
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item label="企负责人" prop="contacts">
        <el-input id="bgColor" v-model="regist_form.contacts"></el-input>
      </el-form-item>
      <el-form-item label="企业电话" prop="enterprisePhone">
        <el-input id="bgColor" v-model="regist_form.enterprisePhone"></el-input>
      </el-form-item>
      <el-form-item label="企业地址" prop="address">
        <el-input id="bgColor" v-model="regist_form.address"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="regist()">立即注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import leftImg from "./left_img.vue";
// 手机号的正则
// var ph = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
export default {
  data() {
    return {
	  //url  反面
	   BaseUrl:this.$store.state.BaseUrl+"admin/add_app_analysis",
      // 图片绑定
      chenge_img: 0,
      // 绑定
      regist_form: {
        enterpriseName: "",
        contacts: "",
        enterprisePhone: "",
        address: "",
        jq_user_qid: ""
      },
      q_image: "",
      // 验证规则
      regist_rules: {
        enterpriseName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur"
          }
        ],
        contacts: [
          { required: true, message: "请输入企业联系人", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur"
          }
        ],
        enterprisePhone: [
          { required: true, message: "企业联系手机号不能为空", trigger: "blur" }
        ],
        address: [
          { required: true, message: "请输入企业地址", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 5 到 50 个字符",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: {
    leftImg
  },
  computed: {
    headers() {
      return {
        Authorization: window.localStorage.getItem("token")
      };
    }
  },
  methods: {
    //   正面图片判断
    handleAvatarSuccess(res, file) {
      if (res.code == 201) {
        this.chenge_img++;
        this.q_image = URL.createObjectURL(file.raw);
        this.$message({
          type: "success",
          message: res.msg
        });
        this.$router.push("/login");
      } else {
        this.$message({
          type: "error",
          message: res.msg
        });
      }
    },
    //   正面图片判断
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 注册
    regist() {
      this.$refs.form_ref.validate(valid => {
        if (valid) {
          this.regist_form.jq_user_qid = window.localStorage.getItem("user_id");
          this.$refs.upload_img.submit();
        }else{
          this.$message({
              message: '请填写完整',
              type: 'warning'
            })
        }
      });
    }
  }
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  background-color: #f0f0f0;
  width: 100%;
  height: 138px;
  line-height: 138px;
  display: inline-block;
  text-align: center;
}
.position {
  width: 100%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
}
.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
.avatar-uploader {
  float: left;
}
#bgColor:focus {
  border: 2px solid rgb(40, 56, 82);
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(40, 56, 82, 0.15);
}
.el-form {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-10%);
  width: 450px;
  background-color: #fff;
  padding: 50px 20px;
}
.regist_header {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 20px;
}
.el-input,
.upload_img {
  width: 90%;
}
@media screen and (max-width: 1240px) {
  .el-form {
    left: 38%;
  }
}
@media screen and (max-width: 550px) {
  .el-form {
    width: 80%;
    margin-bottom: 50px;
    top: 0;
    left: 12%;
  }
  .avatar-uploader-icon {
    width: 80%;
  }
}
</style>